import {
  ClaimSellerSaleProcessingDetailsOutput,
  Sale,
  SaleDeclineRequest,
  User,
} from '@qogita/canary-types'
import {
  clearUser as clearLoggerUser,
  datadogRum,
  identifyUser as identifyLoggerUser,
  logError,
} from '@qogita/logging'
import { AnalyticsBrowser } from '@segment/analytics-next'

import { env } from '#src/env.mjs'

const SEGMENT_WRITE_KEY = env.NEXT_PUBLIC_SEGMENT_WRITE_KEY

const analytics = AnalyticsBrowser.load(
  {
    writeKey: SEGMENT_WRITE_KEY,
    cdnURL: 'https://analytics.qogita.com',
  },
  {
    integrations: {
      'Segment.io': {
        apiHost: 'analyticsapi.qogita.com/v1',
      },
    },
  },
)

const LINKED_IN_CONVERSION_IDS = {
  SignUpSubmitted: 15059634,
}

const GOOGLE_ADS_CONVERSION_ID = 'AW-628038862'

const GOOGLE_CONVERSION_LABELS = {
  SignUpSubmitted: 'hBo7CIrRpfgYEM65vKsC',
} as const

const trackLinkedInConversion = async (
  type: keyof typeof LINKED_IN_CONVERSION_IDS,
) => {
  if (!window.lintrk) {
    return
  }

  const conversionId = LINKED_IN_CONVERSION_IDS[type]
  try {
    await window.lintrk('track', { conversion_id: conversionId })
  } catch {
    // ignore linkedin tracking errors because they are often caused extensions
    // and spikes in the error rate are not actionable
    // also if it's really important, the relevance team will
    // notice the drop in the conversion rate and will ask us to investigate
  }
}

const gtagConversion = (labelKey: keyof typeof GOOGLE_CONVERSION_LABELS) => {
  if (!window.gtag) return

  const conversionLabel = GOOGLE_CONVERSION_LABELS[labelKey]
  window.gtag('event', 'conversion', {
    send_to: `${GOOGLE_ADS_CONVERSION_ID}/${conversionLabel}`,
  })
}

type AnalyticsEvent =
  | 'User Signed In'
  | 'User Signed Out'
  | 'User Phone Verified'
  | 'User Email Verified'
  | 'User Tried To Sign In With Buyer Account'
  | 'User Terms And Conditions Action'
  | 'Sale Viewed'
  | 'Sale Viewed (New)'
  | 'Sale Saleline Quantities Confirmed'
  | 'Sale Shipping Fee Updated'
  | 'Sale VAT Updated'
  | 'Sale Lines Downloaded'
  | 'Sale Proforma Downloaded'
  | 'Sale Invoice Downloaded'
  | 'Salelines Updated via Invoice'
  | 'Sale Payment Confirmation Downloaded'
  | 'Sale Commercial Invoice Downloaded'
  | 'Sale Shipping Details Track And Trace Number Added'
  | 'Sale Shipping Details Track And Trace Number Removed'
  | 'Sale Additional Documents Uploaded'
  | 'Sale Addionional Documents Type Updated'
  | 'Sale Payment Triggered'
  | 'Sale Declined'
  | 'Sale View All Claims Clicked'
  | 'SaleLine Updated'
  | 'Sale Ship With Qogita Confirmed'
  | 'Dashboard Let Us Know What You Think Clicked'
  | 'Dashboard View All Current Orders Clicked'
  | 'Dashboard New Order Clicked'
  | 'Dashboard Expiring Order Clicked'
  | 'Dashboard Learn More Inventory Last Processed Clicked'
  | 'Dashboard View All Trending Products Clicked'
  | 'Dashboard View All Insights Clicked'
  | 'HelpCenter Seller Handbook Clicked'
  | 'HelpCenter Terms And Conditions Clicked'
  | 'HelpCenter Resale Certificates Clicked'
  | 'HelpCenter Report An Issue Clicked'
  | 'Inventory Download Overview Clicked'
  | 'Inventory Copy Link Clicked'
  | 'Inventory Discount Applied To Inventory Changed'
  | 'Inventory Brand Price Reduction Contact Us Clicked'
  | 'Inventory Boost Revenue Contact Us Clicked'
  | 'Demand Ranked CTA Contact Us Clicked'
  | 'SearchBar Sale Clicked'
  | 'SearchBar Offer Clicked'
  | 'Profile Discount Updated'
  | 'Profile Chamber Of Commerce Number Updated'
  | 'Profile Billing Address Updated'
  | 'Profile Shipping Address Updated'
  | 'Profile Payment Details Updated'
  | 'MarketingPage Typeform Opened'
  | 'MarketingPage Portal Next Step Clicked'
  | 'MarketingPage FAQ Expanded'
  | 'LoginPage Typeform Opened'
  | 'Footer Link Clicked'
  | 'PausedSellerModal Contact Us Clicked'
  | 'PausedSellerInlineNotification Contact Us Clicked'
  | 'Claims Order ID Clicked'
  | 'TrendingProducts Trending Product Modal Opened'
  | 'TrendingProducts Download Overview Clicked'
  | 'Brand Specific Price Reduction Created'
  | 'Brand Specific Price Reduction Deleted'
  | 'Sign Up Submitted'
  | 'Inventory Status Filter Applied'
  | 'Inventory Filter Applied'
  | 'Orders Filter Applied'
  | 'Orders Downloaded'

/**
 * Performs a Segment `track` call to record a user action. This call must
 * follow convention. See https://github.com/qogita/web#conventions.
 * @param object The name of the Object in Title Case.
 * @param action The name of the Action in Title Case.
 * @param properties An optional object containing extra information about the
 *                   Action. Keys should be written in camelCase.
 */
function track(event: AnalyticsEvent, properties: object | undefined) {
  try {
    analytics.track(event, properties)
  } catch (error) {
    logError(error)
  }
}

type Identify = (userId: string, traits?: { [key: string]: unknown }) => void

const identify: Identify = (userId, traits) => {
  try {
    analytics.identify(userId, traits)
  } catch (error) {
    logError(error)
  }
}

export const identifyUser = (user: User) => {
  identifyLoggerUser(user)
  identify(user.qid, {
    email: user.email,
    name: user.account,
    createdAt: user.createdAt,
    company: user.company,
    isKeyAccount: !!user.accountManager,
    sellerStatus: user.sellerStatus,
    pauseReason: user.pauseReason,
    expectedPauseEnd: user.expectedPauseEnd,
  })
}

export const removeUserIdentificationFromThirdParties = () => {
  try {
    analytics.reset()
    clearLoggerUser()
  } catch (error) {
    logError(error)
  }
}

export const identifyPage = (name: string, query: object) => {
  try {
    analytics.page(undefined, name, query)
  } catch (error) {
    logError(error)
  }
}

export const trackUserSignedIn = (user: User) => {
  track('User Signed In', {
    email: user.email,
  })
}

export const trackUserSignedOut = () => {
  track('User Signed Out', undefined)
}

export const trackSaleViewedNew = (sale: Sale) => {
  track('Sale Viewed (New)', {
    saleQid: sale.qid,
    status: sale.status,
    order: sale.order,
  })
}

export const trackSaleSalelineQuantitiesConfirmed = (sale: Sale) => {
  track('Sale Saleline Quantities Confirmed', {
    saleQid: sale.qid,
  })
}

export const trackSaleLinesDownloaded = (sale: Sale) => {
  track('Sale Lines Downloaded', {
    saleQid: sale.qid,
    order: sale.order,
  })
}

export const trackSalePaymentConfirmationDownloaded = (sale: Sale) => {
  track('Sale Payment Confirmation Downloaded', {
    saleQid: sale.qid,
    order: sale.order,
  })
}

export const trackSaleInvoiceDownloaded = (sale: Sale) => {
  track('Sale Invoice Downloaded', {
    saleQid: sale.qid,
    order: sale.order,
  })
}

export const trackSaleCommercialInvoiceDownloaded = ({
  sale,
  ...props
}: {
  sale: Sale
  isPreGenerated: boolean
}) => {
  track('Sale Commercial Invoice Downloaded', {
    ...props,
    saleQid: sale.qid,
    order: sale.order,
  })
}

export const trackSaleAdditionalDocumentsUploaded = (
  sale: Pick<Sale, 'qid' | 'order'>,
) => {
  track('Sale Additional Documents Uploaded', {
    saleQid: sale.qid,
    order: sale.order,
  })
}

export const trackSaleAdditionalDocumentsTypeUpdated = ({
  saleQid,
  order,
  documentQid,
  documentType,
}: {
  saleQid: Sale['qid']
  order: Sale['order']
  documentQid: string
  documentType: string
}) => {
  /*
    Please don't fix the typo because we lose the tracking history.
   */
  track('Sale Addionional Documents Type Updated', {
    saleQid,
    order,
    documentQid,
    documentType,
  })
}

export const trackSalePaymentTriggered = (saleQid: string) => {
  track('Sale Payment Triggered', {
    saleQid,
  })
}

export const trackSaleDeclined = (
  saleQid: string,
  data: SaleDeclineRequest,
) => {
  track('Sale Declined', {
    saleQid,
    ...data,
  })
}

export const trackSaleLineUpdated = (data: {
  saleQid: string
  saleLineQid: string
  price: string
  quantity: string
  quantityReductionReason: string
}) => {
  track('SaleLine Updated', data)
}

export const trackSalelinesUpdatedViaInvoiceUploaded = (data: {
  saleQid: string
  gtinColumnName: string
  quantityColumnName: string
  salelineCount: number
}) => {
  track('Salelines Updated via Invoice', data)
}

export const trackDashboardViewAllCurrentOrdersClicked = () => {
  track('Dashboard View All Current Orders Clicked', undefined)
}

export const trackDashboardNewOrderClicked = (sale: Sale) => {
  track('Dashboard New Order Clicked', sale)
}

export const trackDashboardExpiringOrderClicked = (sale: Sale) => {
  track('Dashboard Expiring Order Clicked', sale)
}

export const trackHelpCenterSellerHandbookClicked = () => {
  track('HelpCenter Seller Handbook Clicked', undefined)
}

export const trackHelpCenterTermsAndConditionsClicked = () => {
  track('HelpCenter Terms And Conditions Clicked', undefined)
}

export const trackHelpCenterResaleCertificatesClicked = () => {
  track('HelpCenter Resale Certificates Clicked', undefined)
}

export const trackHelpCenterReportAnIssueClicked = () => {
  track('HelpCenter Report An Issue Clicked', undefined)
}

export const trackInventoryDownloadOverviewClicked = (props: {
  withFilters: boolean
}) => {
  track('Inventory Download Overview Clicked', props)
  datadogRum.addAction('Inventory Download Overview Clicked', props)
}

export const trackInventoryDiscountAppliedToInventoryChanged = (data: {
  from: string
  to: string
}) => {
  track('Inventory Discount Applied To Inventory Changed', data)
}

export const trackBrandSpecificPriceReductionDeleted = (data: {
  brandName: string
  brandDiscountQid: string
  discount: string
}) => {
  track('Brand Specific Price Reduction Deleted', {
    brandName: data.brandName,
    brandDiscountQid: data.brandDiscountQid,
    discount: data.discount,
  })
}

export const trackBrandSpecificPriceReductionCreated = (data: {
  brandName: string
  brandQid: string
  discount: string
}) => {
  track('Brand Specific Price Reduction Created', {
    brandName: data.brandName,
    brandQid: data.brandQid,
    discount: data.discount,
  })
}

export const trackSearchBarSaleClicked = (props: {
  saleQid: string
  order: string
  status: Sale['status']
}) => {
  track('SearchBar Sale Clicked', props)
  datadogRum.addAction('Search Result Clicked', {
    type: 'sale',
    status: props.status,
  })
}

export const trackSearchBarOfferClicked = (props: {
  offerQid: string
  gtin: string
}) => {
  track('SearchBar Offer Clicked', props)
  datadogRum.addAction('Search Result Clicked', {
    type: 'offer',
  })
}

export const trackProfileChamberOfCommerceNumberUpdated = () => {
  track('Profile Chamber Of Commerce Number Updated', undefined)
}

export const trackProfileBillingAddressUpdated = () => {
  track('Profile Billing Address Updated', undefined)
}

export const trackProfileShippingAddressUpdated = () => {
  track('Profile Shipping Address Updated', undefined)
}

export const trackProfilePaymentDetailsUpdated = () => {
  track('Profile Payment Details Updated', undefined)
}

export const trackUserPhoneVerified = () => {
  track('User Phone Verified', undefined)
}

export const trackUserEmailVerified = () => {
  track('User Email Verified', undefined)
}

export const trackMarketingPageTypeformOpened = (props: {
  typeformButtonText: string
  typeformID: string
}) => {
  track('MarketingPage Typeform Opened', props)
}

export const trackMarketingPagePortalNextStepClicked = () => {
  track('MarketingPage Portal Next Step Clicked', undefined)
}

export const trackMarketingPageFAQExpanded = (props: {
  faqQuestion: string
}) => {
  track('MarketingPage FAQ Expanded', props)
}

export const trackFooterLinkClicked = (props: { link: string }) => {
  track('Footer Link Clicked', props)
}

export const trackPausedSellerModalContactUsClicked = () => {
  track('PausedSellerModal Contact Us Clicked', undefined)
}

export const trackDemandRankedCTAContactUsClicked = () => {
  track('Demand Ranked CTA Contact Us Clicked', undefined)
}

export const trackInventoryBrandPriceReductionContactUsClicked = () => {
  track('Inventory Brand Price Reduction Contact Us Clicked', undefined)
}

export const trackPausedSellerInlineNotificationContactUsClicked = () => {
  track('PausedSellerInlineNotification Contact Us Clicked', undefined)
}

export const trackClaimsOrderIDClicked = (
  claim: ClaimSellerSaleProcessingDetailsOutput,
) => {
  track('Claims Order ID Clicked', {
    ...claim,
    saleQid: claim.saleQid,
    order: claim.orderFid,
  })
}

export const trackLoginPageTypeformOpened = (props: { typeformID: string }) => {
  track('LoginPage Typeform Opened', props)
}

export const trackUserTriedToSignInWithBuyerAccount = (user: User) => {
  track('User Tried To Sign In With Buyer Account', {
    qid: user.qid,
    email: user.email,
  })
}

export const trackUserTermsAndConditionsAction = (props: {
  action: 'agreed' | 'cancelled' | 'dismissed' | 'viewed'
}) => {
  track('User Terms And Conditions Action', props)
}

export const trackViewAllTrendingProductsClicked = ({
  gtins,
}: {
  gtins: string[]
}) => {
  track('Dashboard View All Trending Products Clicked', { gtins })
}

export const trackPageTrendingProductOpened = ({ gtin }: { gtin: string }) => {
  track('TrendingProducts Trending Product Modal Opened', { gtin })
}

export const trackTrendingProductsDownloadClicked = () => {
  track('TrendingProducts Download Overview Clicked', undefined)
}

export const trackDashboardViewAllInsightsClicked = () => {
  track('Dashboard View All Insights Clicked', undefined)
}

export const trackSignUpFormSubmitted = (props: {
  typeformId: string
  typeformResponseId: string
}) => {
  try {
    trackLinkedInConversion('SignUpSubmitted')
    gtagConversion('SignUpSubmitted')
    const eventName = 'Sign Up Submitted' // it's used in Segment
    track(eventName, props)
  } catch (error) {
    logError(error)
  }
}

export const trackInventoryFiltered = (props: {
  type: 'status' | 'brand' | 'percentage-to-winning-price' | 'sort'
  brand?: string
  status?: string
  range?: {
    min: number
    max: number
  }
  sort?: string
  source:
    | 'statisticsCards'
    | 'statusFilter'
    | 'brandsFilter'
    | 'percentageToWinningPriceFilter'
    | 'inventoryTable'
}) => {
  track('Inventory Filter Applied', props)
  datadogRum.addAction('Inventory Filter Applied', props)
}

export const trackOrdersFilterApplied = (props: {
  type: 'next-action' | 'destination' | 'sort'
  value: string
}) => {
  track('Orders Filter Applied', props)
  datadogRum.addAction('Orders Filter Applied', props)
}

export const trackDownload = (props: {
  type: 'bulk-order-lines' | 'bulk-invoices'
}) => {
  track('Orders Downloaded', props)
  datadogRum.addAction('Orders Downloaded', props)
}

export const trackSaleShipWithQogitaConfirmed = (props: {
  saleQid: string
}) => {
  track('Sale Ship With Qogita Confirmed', props)
  datadogRum.addAction('Sale Ship With Qogita Confirmed', props)
}
